import {useState, useEffect } from 'react';
import {singleFetch} from 'common/singleFetch'
import { getApiPath } from 'common/functions';

const USER_ID = "userId";

export function UserControl({userId, setUserId}) {
    function getNewId() {
      localStorage.removeItem(USER_ID);
      window.location.pathname = '/'
    }
    
    function useNewId() {
      window.location.pathname = `/${newUserId}`
    }
    
    const [newUserId, setNewUserId] = useState('');
    const [showGetNewId, setShowGetNewId] = useState(false);
    const [userIdStatusHtml, setUserIdStatusHtml] = useState(<></>);
  
    useEffect(() => {
      if (userId == null) {
        let pathUserId = window.location.pathname.split('/')[1];
        let storedId = localStorage.getItem(USER_ID);
        if (pathUserId !== '') {
          //Confirming current user Id
          let url = `${getApiPath()}getUser/${pathUserId}`;
          singleFetch(url)
            .then((jsonResponse) => {
              if (jsonResponse.userIdValid === true) {
                localStorage.setItem(USER_ID, jsonResponse.sId);
                setUserId(jsonResponse.sId);
                setUserIdStatusHtml(<>Your current id is: <b>{jsonResponse.sId}</b>. Please remember this id if you wish to use this account from other devices.</>);
              } else {
                if (pathUserId === storedId) {
                  setUserIdStatusHtml(<>Your current stored id <b>{storedId}</b> is INVALID. It could have been deleted due to inactivity.</>);
                  setShowGetNewId(true);
                } else {
                  setUserIdStatusHtml(<>The user id <b>{pathUserId}</b> is invalid.</>);
                }
              }
            })
            .catch((error) => {
              console.log(`Cought error (UserControl): ${error}`);
              setUserIdStatusHtml(<>Could not validate user id, try refreshing the page</>);
            });
        } else if (storedId !== null) {
          window.location.pathname = '/'+storedId;
        } else {
          //Creating new user
          let url = `${getApiPath()}createUser`;
          singleFetch(url, {method : "POST"})
            .then((jsonResponse) => {
              window.location.pathname = '/'+jsonResponse.sId;
            })
            .catch((error) => {
              console.log(`Cought error (UserControl/2): ${error}`);
              setUserIdStatusHtml(<>Could not obtain a new user id, try refreshing the page</>);
            });
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);
       
    return (
    <div>
        {userIdStatusHtml}
        <div className='input-group' style={{width:"14em", marginTop:"5pt"}}>
          <div className="form-floating">
            <input className="form-control form-control-sm" placeholder="Use a different User Id" value = {newUserId} onChange={(e) => {setNewUserId(e.target.value)}}/>
            <label>Use a different user Id</label>
          </div>
          <button className="btn btn-outline-primary" disabled={newUserId === ''} onClick={useNewId}>Go</button>
        </div>
        
        {showGetNewId && <button className="btn btn-outline-primary" style={{marginTop:"10pt"}} onClick={getNewId}>Get New Id</button>}
    </div>
    );
}