import { formatDataSize, getApiPath } from "common/functions";
import { singleFetch } from "common/singleFetch";
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";

export function Download() {

    const[fileData, setFileData] = useState(/**@type{DownloadFileData}*/(null));
    const fileId = useLocation().pathname.split("/")[2];

    useEffect(() => {
        if (fileData != null) {
            return;
        }
        const url = `${getApiPath()}fileData/${fileId}`;
        singleFetch(url).then((response)=> {
            setFileData(new DownloadFileData(response));
        }).catch((e) => {
            console.log(e);
            setFileData(new DownloadFileData({}));
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileData]); 

    let resultView = null;
    if (fileData != null) {
        if (fileData.otherError) {
            resultView = <SomeError/>;
        } else if (fileData.badId) {
            resultView = <InvalidFileId fileId={fileId}/>;
        } else if (fileData.notFound) {
            resultView = <FileNotFound fileId={fileId}/>;
        } else {
            resultView = <DownloadFile fileId ={fileId} downloadFileData={fileData}/>;
        }
    }

    return <div style={{display:"flex", flexDirection:"column", flex:"1", alignItems:"center"}}>
        <div style={{flex:"1"}}/>
        {resultView && <div style={{flex:"3", textAlign:"center"}}>
            <div className="boxBackground">
                {resultView}
            </div>
        </div>}
    </div>
}

function SomeError() {
    return <div>
        <h1>Something Went Wrong!</h1>
        <h2>Unable to retrieve file data</h2>
        <h3>Try again later</h3>
    </div>
}

function InvalidFileId({fileId}) {
    return <div>
        <h1>The file you are trying to access has a malformed Id: {fileId}</h1>
        <h3>Please double check the source (URL) of the file</h3>
    </div>
}

function FileNotFound({fileId}) {
    return <div>
        <h1>The file with id {fileId}, not found.</h1>
        <h3>It could of been deleted or it never existed in the first place.</h3>
        <h3>Please double check the source (URL) of the file.</h3>
    </div>
}

/**
 * @param {Object} param
 * @param {DownloadFileData} param.downloadFileData
 */
function DownloadFile({downloadFileData, fileId}) {
    const url = `${getApiPath()}download/${fileId}`;
    const size = formatDataSize(downloadFileData.size);
    return <div>
        <h3>You are about to download</h3>
        <h4>{downloadFileData.fileName}</h4>
        <h5>{size}</h5>
        <a className="btn btn-primary" style={{fontSize:"1.5rem", marginTop:"10pt"}} href={url}>
            <DownloadSvg style={{display:"inline-block", marginRight:"1rem"}}/>
            Download
        </a>
    </div>
}

function DownloadSvg({style}) {
    return <div style={style}>
        <svg width="2rem" height="2rem" fill="var(--bs-white)" class="bi bi-cloud-arrow-down" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708z"/>
            <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
        </svg>
  </div> 
}

class DownloadFileData {
    badId = false;
    notFound = false;
    otherError = false;
    fileName = null;
    size = null;

    constructor(o) {
        if (o.fileName) {
            this.fileName = o.fileName;
            this.size = o.size;
        } else if (o.notFound) {
            this.notFound = true;
        } else if (o.badId) {
            this.badId = true;
        } else {
            this.otherError = true;
        }
    }
}