import { getApiPath } from "common/functions";
import { singleFetch } from "common/singleFetch";
import { useEffect, useRef, useState } from "react";

export function FileUploadControl({userId, onUploadFinished}) {   
    function startUpload() {
        setIsUploading(true);
        setUploadProgressStatus("Initiating Upload");
        setUploadProgressNumber(0);
        uploadCanceler.current = new AbortController();

        let uploadIdInProgress = null;

        const url = `${getApiPath()}createUpload/${userId}`;
        const uploadIdPromise = singleFetch(url, {method:"POST", signal:uploadCanceler.current.signal});

        uploadIdPromise.then((response) => {
            uploadIdInProgress = response.uploadId;
            const url = `${getApiPath()}Upload/${uploadIdInProgress}?fileName=${newFileName}`;
            return singleFetch(url, {method:"POST", body:fileToUpload, signal:uploadCanceler.current.signal});
        }).then((response) => {
            if (response.fileId) {
                setUploadProgressStatus(null);
                onUploadFinished();
            } else if (response.insuficientStorage) {
                setUploadProgressStatus("Not enough storage on the server");
            } else {
                setUploadProgressStatus("Something went wrong");
            }
        }).catch((e) => {
            if (e === "Cancell button") {
                setUploadProgressStatus(null);
            } else if (e === "Insuficient Storage") {
                setUploadProgressStatus("Not enough storage on the server");
            } else if (e === "Connection Interrupted") {
                setUploadProgressStatus("Upload connection was interupted, please try again");
            } else {
                setUploadProgressStatus("Unexpected error. Try refreshing the page");
            }
        }).finally(() => {
            uploadIdInProgress = null;
            setIsUploading(false);
        });

        uploadIdPromise.then(async (response) => {
            const monitoredUploadId = response.uploadId;
            const url = `${getApiPath()}UploadProgress/${monitoredUploadId}`;
            do {
                const response = await singleFetch(url);

                if (response.status === 2) {
                    uploadCanceler.current.abort("Insuficient Storage");
                }

                if (response.status === 3) {
                    uploadCanceler.current.abort("Connection Interrupted");
                }

                if (response.uploaded !== undefined && response.total !== 0) {
                    const uploadProgress = response.uploaded / response.total * 100;
                    const uploadProgresNumber = Math.floor(uploadProgress);
                    setUploadProgressNumber(uploadProgresNumber);
                    setUploadProgressStatus(`${uploadProgresNumber}%`);
                }

                await new Promise((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, 5_000);
                });
            } while (monitoredUploadId === uploadIdInProgress);
        });
    }

    const uploadCanceler = useRef(new AbortController());
    const[isUploading, setIsUploading] = useState(false);
    const[fileToUpload, setFileToUpload] = useState(/**@type {File}*/ (null));
    const[newFileName, setNewFileName] = useState("");
    const[uploadProgressStatus, setUploadProgressStatus] = useState(/**@type {string}*/ (null));
    const[uploadProgressNumber, setUploadProgressNumber] = useState(0);

    useEffect(()=>{
        if (fileToUpload == null) {
            setNewFileName("");
        } else {
            setNewFileName(fileToUpload.name);
        }
    }, [fileToUpload]);

    let uploadProgressColor = "var(--bs-indigo)";
    if (uploadProgressNumber > 50) {
        uploadProgressColor = "var(--bs-primary-bg-subtle)"
    }

    return <div style={{display:"flex", alignItems:"end"}}>
        <div style={{width:"20em", marginRight:"15pt"}}>
            <label style={{marginBottom:"5pt"}}>UploadFile</label>
            <input className="form-control form-control-sm" type="file" onChange={(e) => setFileToUpload(e.target.files[0])}/>
        </div>

        <div style={{width:"20em"}}>
            <label>New File Name</label>
            <div className="input-group input-group-sm">
                <input className="form-control form-control-sm" type="text" disabled={fileToUpload == null || isUploading} value={newFileName} onChange={(e)=>{setNewFileName(e.target.value)}}/>
                {!isUploading && <button className="btn btn-outline-primary" disabled={fileToUpload == null || newFileName === ""} onClick={startUpload}>Upload</button>}
                {isUploading && <button className="btn btn-outline-primary" onClick={()=>uploadCanceler.current.abort("Cancell button")}>Cancel</button>}
            </div>
        </div>

        {uploadProgressStatus && <div style={{flex:"1", position:"relative"}}>
            <div className="progress" style={{height:"1.875rem", marginLeft:"15pt"}}>
                <div className="progress-bar progress-bar-striped progress-bar-animated bg-success" style={{width: uploadProgressNumber+'%'}}></div>
            </div>
            <div style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)", color:uploadProgressColor}}><b>{uploadProgressStatus}</b></div>
        </div>}
    </div>;
}

