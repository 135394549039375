import { UserControl } from "components/UserControl";
import { useState } from "react";
import { FileUploadControl } from "components/FileUploadControl";
import { FileList } from "components/FileList";
import { AvailableServerStorage } from "components/AvailableServerStorage";

export function Main() {
  function onUploadFinished() {
    fileListRefreshRef.refresh();
    availableStorageRefreshRef.refresh();
  }

  function onFileDeleted() {
    availableStorageRefreshRef.refresh();
  }
  
  const fileListRefreshRef = {refresh : null};
  const availableStorageRefreshRef = {refresh : null};
  const [userId, setUserId] = useState(null);
  return <div>
    <div style={{textAlign:"center"}}>
      <h4 style={{marginBlockStart:"1pt", marginBlockEnd:"1pt", color:"var(--bs-gray-600)"}}>Attention!!!</h4>
      This website was <b>not developed</b> with a focus on security, so please be mindful of the content you upload.<br/> 
      If you choose to upload files containing sensitive information, ensure they are deleted as soon as they are no longer needed.<br/>
      To manage this, you can use the settings "Delete After (x) Downloads" and "Delete After."
    </div>
    <div style={{margin:"10pt"}}>
      <UserControl userId={userId} setUserId = {setUserId}/>
      <div style={{height:"1pt", margin:"10pt 0 5pt 0", backgroundColor:"var(--bs-primary)"}}/>
      {userId && <FileUploadControl userId = {userId} onUploadFinished={onUploadFinished}/>}
      <div style={{height:"5pt"}}/>
      {userId && <AvailableServerStorage refreshRef={availableStorageRefreshRef}/>}
      <div style={{height:"1pt", margin:"10pt 0 5pt 0", backgroundColor:"var(--bs-primary)"}}/>
      {userId && <FileList userId={userId} refreshRef={fileListRefreshRef} onFileDeleted={onFileDeleted}/>}
    </div>
  </div>
}
