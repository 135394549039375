import { Main } from "components/pages/Main";
import {BrowserRouter, Route, Routes } from "react-router-dom";
import { Download } from "components/pages/Download";
import { Version } from "./Version";

export function App() {
  return <div style={{margin:"0pt", display:"flex", flexDirection:"column", height:"100vh"}}>
    <div className="gradient_blue_background" style={{display:"flex", alignItems:"center", padding:"15pt", color:"var(--bs-primary-bg-subtle)"}}>
      <div style={{marginLeft:"5pt", display:"flex", alignItems:"end", flexGrow:"1"}}>
        <h1 style={{marginBlockStart:"0", marginBlockEnd:"0"}}><i>Easy Share</i></h1>
        <h5 style={{marginLeft:"15pt", marginBlockStart:"0", marginBlockEnd:"3pt"}}>Share your files the easiest way possible with anyone who has a browser.</h5>
      </div>
      <div style={{marginRight:"5pt"}}>
        Developed by a Smog Technician<br/>who works at&nbsp;
        <img src="/img/SmogBustersLogo.png" alt="" style={{height:"20pt"}}/><br/>Las Vegas, NV
      </div>
    </div>
    <BrowserRouter>
      <Routes>
        <Route path="/:UserId?" element={<Main/>}/>
        <Route path="/download/:FileId" element={<Download/>}/>
        <Route path="/version/frontend" element={<Version/>}/>
      </Routes>
    </BrowserRouter>
  </div>
}